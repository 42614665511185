
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import type ProvidersService from '@/modules/providers/providers.service';

@Component({
    components: {
        CustomTooltip,
    },
})
export default class RatesScanTooltip extends Vue {
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    get isLimited() {
        return this.scansRemaining !== -1;
    }

    get tooltipWidth() {
        return 130 + this.scansRemaining.toString().length * 10;
    }

    get provider() {
        const { scanLimitation } = this.ratesCommonService;

        if (!scanLimitation) {
            return -1;
        }

        const { provider } = scanLimitation;
        return provider ? this.providersService.allProviders[provider].label : '';
    }

    get scansRemaining() {
        const { scanLimitation } = this.ratesCommonService;

        if (!scanLimitation) {
            return -1;
        }

        return scanLimitation.remaining;
    }
}
