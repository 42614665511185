
import { Vue, Component, Prop } from 'vue-property-decorator';
import HotelSpinner from './hotel-spinner.vue';

@Component({ components: { HotelSpinner } })
export default class CustomLoader extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    isLoading!: boolean;

    @Prop({
        type: String,
        required: false,
    })
    backgroundColor?: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    isShownOnMount!: boolean;

    private parentElement: HTMLElement | null = null;

    private lastParentPosition = '';

    mounted() {
        this.setParentStyles(this.isLoading);
    }

    updated() {
        this.setParentStyles(this.isLoading);
    }

    beforeDestroy() {
        this.setParentStyles(false);
    }

    setParentStyles(isLoading: boolean) {
        const loaderElement = this.$refs.loader as HTMLElement;

        if (!loaderElement && !this.parentElement) {
            return;
        }

        if (loaderElement && !this.parentElement) {
            this.parentElement = loaderElement.parentElement;
        }

        if (this.parentElement && isLoading) {
            if (getComputedStyle(this.parentElement).position === 'static' && !this.parentElement.style.position) {
                this.parentElement.style.position = 'relative';
            } else if (this.parentElement.style.position === 'static') {
                this.lastParentPosition = 'static';
                this.parentElement.style.position = 'relative';
            } else if (this.parentElement.style.position) {
                this.lastParentPosition = this.parentElement.style.position;
            }

            this.parentElement.style.overflow = 'hidden';
            this.parentElement.style.overflowX = 'hidden';
            this.parentElement.style.overflowY = 'hidden';

            if (loaderElement) {
                loaderElement.style.top = `${this.parentElement.scrollTop}px` || '0px';
                loaderElement.style.bottom = `-${this.parentElement.scrollTop}px` || '0px';
                loaderElement.style.left = `${this.parentElement.scrollLeft}px` || '0px';
                loaderElement.style.right = `-${this.parentElement.scrollLeft}px` || '0px';
            }
        } else if (this.parentElement && !isLoading) {
            if (this.lastParentPosition === 'static') {
                this.parentElement.style.position = 'static';
            } else if (!this.lastParentPosition) {
                this.parentElement.style.position = '';
            }

            this.parentElement.style.overflow = '';
            this.parentElement.style.overflowX = '';
            this.parentElement.style.overflowY = '';
        }
    }
}
