
import { Component, Vue, Prop } from 'vue-property-decorator';

interface ICompsetDataType {
    isNA: boolean,
    isNoData: boolean,
    isSoldOut: boolean,
    noComparisonData: boolean,
    pax: number[] | null,
}

// number is for pax
type NoDataMod = 'na' | 'soldOut' | 'noComp' | 'noData' | number[];

@Component
export default class CompsetScale extends Vue {
    @Prop({ required: true })
    color!: {};

    // [TODO] remove, when everywhere will be replaced with noDataMod prop
    @Prop({
        type: Object,
        required: false,
        default() {
            return {
                isNA: false,
                isNoData: false,
                isSoldOut: false,
                noComparisonData: false,
                pax: null,
            };
        },
    })
    compsetDataType!: ICompsetDataType;

    @Prop({ type: String, default: null })
    noDataMod!: NoDataMod | null;

    get isNa() {
        if (this.noComparisonData) {
            return false;
        }
        return this.compsetDataType && this.compsetDataType.isNA;
    }

    get isNoData() {
        if (this.noComparisonData) {
            return false;
        }
        return this.compsetDataType && this.compsetDataType.isNoData;
    }

    get isSoldOut() {
        if (this.noComparisonData) {
            return false;
        }
        return this.compsetDataType && this.compsetDataType.isSoldOut;
    }

    get noComparisonData() {
        return this.compsetDataType && this.compsetDataType.noComparisonData;
    }

    // [TODO] make compsetDataType prop to be just a string
    // make union type na | nocomp | nodata | soldout
    get mod(): NoDataMod | null {
        if (this.noDataMod) return this.noDataMod;
        if (this.compsetDataType.noComparisonData) return 'noComp';
        if (this.compsetDataType.isNA) return 'na';
        if (this.compsetDataType.isNoData) return 'noData';
        if (this.compsetDataType.pax) return this.compsetDataType.pax;
        if (this.compsetDataType.isSoldOut) return 'soldOut';
        return null;
    }
}
